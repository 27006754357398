/* eslint-disable */
import { call } from "redux-saga/effects";
import { disagreePrmProtocol, disagreePrmProtocolSingle, wxLogin } from "../LoginService";

export default function* prmProtocolDisagree(action) {
  const { source, device, mobileParams } = action.payload

  // 小程序快速登录
  if (source === 'channelwxmp') {
    yield call(wxLogin)
  } else {
    // 单点登录
    if (device) {
      yield call(disagreePrmProtocolSingle, { device, mobileParams })
    }
    // 标准登录
    else {
      yield call(disagreePrmProtocol)
    }
  }
}