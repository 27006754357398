/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import AvatarMonitor from '#utils/sentry/AvatarMonitor';
import { get } from "#utils/globalVariable";
import intl from "react-intl-universal";
import { defineMessages } from "#utils/i18n/intl";
import { setAgreeLoading } from '../models/login/action';
import { CircleLoading } from './Loading';
import ConfirmModal from '#components/ConfirmModal';

const messages = defineMessages({
  agreementRead: {
    id: "prm.login.agreement.read",
    defaultMessage: "请仔细阅读以下内容，并做出适当的选择："
  },
  protocolCurrentIndex: {
    id: "prm.login.agreement.index",
    defaultMessage: "第{currentIndex}个协议/共{index}个协议"
  }
});

const Wrapper = styled.div`

  display: flex;
  flex-direction: column;
  height: 100%;

  .modal-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 94px;
    padding-left: 24px;
    padding-right: 24px;

    .modal_head{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .modal_left {
        font-size: 20px;
        color: #222;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .modal_right {
        font-size: 13px;
        color: #AAA;
        white-space: nowrap;
        margin-left: 5px;
      }
    }

    .modal_desc {
      margin-top: 10px;
      font-size: 13px;
      color: #787878;
    }
  }

  .modal_content {
    flex: 1;
    -webkit-overflow-scrolling: touch; 

    .modal_uri {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  .modal_foot {
    display: flex;
    height: 54px;
    justify-content: end;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    .modal_refuse {
      height: 20px;
      min-width: 110px;
      max-width: 150px;
      margin-right: 4px;
      padding: 5px 3px;
      background-color: #FFF;
      border: 1px solid #AAA;
      border-radius: 100px;
      text-align: center;
      font-size: 13px;
      color: #454545;
      white-space: nowrap;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        cursor: pointer;
      }
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    .modal_agree {
      height: 20px;
      min-width: 110px;
      max-width: 150px;
      margin-left: 4px;
      padding: 5px 3px;
      background-color: #0564F5;
      border-radius: 100px;
      text-align: center;
      font-size: 13px;
      color: #FFF;
      white-space: nowrap;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        cursor: pointer;
      }
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
  }
`;

const ButtonLoading = styled(CircleLoading)`
  margin: 0 auto;
  width: 15px;
  height: 15px;
  border-top: 2px solid rgba(255, 255, 255, 0.28);
  border-right: 2px solid rgba(255, 255, 255, 0.28);
  border-bottom: 2px solid rgba(255, 255, 255, 0.28);
  border-left: 2px solid #ffffff;
`;

ReactModal.setAppElement('#modal-slot');

class ProtocolModal extends React.Component {

  componentDidMount() {
    this.setState({
      currentIndex: 0,
      showModal: false
    })
  }

  componentDidUpdate() {
    const { show } = this.props;
    if (show) {
      AvatarMonitor.getInstance().report();
    }
  }

  render() {
    const {
      show,
      prmProtocol,
      buttonLoading,
      fetchAgreeLoading,
      disagree,
      agree
    } = this.props;
    if (!prmProtocol) {
      return null
    }
    const { protocol, device } = prmProtocol

    let showWidth100 = false
    const env = get("env");
    // 单点登录
    if (device && device === 'mobile') {
      showWidth100 = true
    }
    // 标准登录
    else {
      if (env !== 'WEB') {
        showWidth100 = true
      }
    }

    return (
      <>
        <ReactModal
          isOpen={show}
          style={{
            overlay: {
              zIndex: 100,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              width: showWidth100 ? '100vw' : '80vw',
              height: '80vh',
              inset: '0px',
              padding: '0px 5px',
              margin: 'auto auto',
              overflow: 'visible'
            },
          }}
        >
          <Wrapper>
            <div className='modal-top'>
              <div className="modal_head">
                <div className='modal_left'>
                  {protocol[this.state.currentIndex].protocolTitle}
                </div>
                <div className='modal_right'>
                  {intl.formatMessage(messages.protocolCurrentIndex, {
                    currentIndex: this.state.currentIndex + 1,
                    index: protocol.length
                  })}
                </div>
              </div>
              <div className='modal_desc'>
                {intl.formatMessage(messages.agreementRead)}
              </div>
            </div>
            <div className="modal_content">
              <iframe className='modal_uri' src={protocol[this.state.currentIndex].contentUri} frameborder="0" />
            </div>
            <div className="modal_foot">
              <span className='modal_refuse'
                onClick={() => {
                  this.setState({
                    showModal: true
                  })
                }}
              >
                {protocol[this.state.currentIndex].refuseTxt}
              </span>
              <span className='modal_agree'
                onClick={buttonLoading ? null : () => {
                  if (this.state.currentIndex + 1 < protocol.length) {
                    this.setState({
                      currentIndex: this.state.currentIndex + 1
                    })
                  } else {
                    agree(prmProtocol)
                    fetchAgreeLoading()
                  }
                }}
              >
                {buttonLoading ? <ButtonLoading /> : protocol[this.state.currentIndex].agreeTxt}
              </span>
            </div>
          </Wrapper>
        </ReactModal>

        <ConfirmModal
          show={this.state.showModal}
          onCancel={() => {
            this.setState({
              showModal: false
            })
          }}
          onConfirm={() => {
            this.setState({
              showModal: false
            })
            disagree(prmProtocol)
          }}
        />
      </>
    );
  }
}

ProtocolModal.propTypes = {
  show: PropTypes.bool.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  fetchAgreeLoading: PropTypes.func.isRequired,
  disagree: PropTypes.func,
  agree: PropTypes.func
};

ProtocolModal.defaultProps = {
};

// connect组件
function mapStateToProps(state) {
  return {
    buttonLoading: state.agreeLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAgreeLoading() {
      dispatch(setAgreeLoading(true));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolModal);
