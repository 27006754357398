/* eslint-disable */
import React from "react";
import styled from "styled-components";
import queryString from "query-string";
import { Service } from "#models/Service";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MsgModal from '#components/MsgModal';
import ProtocolModal from '#components/ProtocolModal';
import {
    setTenantError,
    setPrmProtocol,
    disagreePrmProtocol,
    agreePrmProtocol
} from '#models/login/action';

const Main = styled.main`
  background: ${(props) =>
        props.backgroundImg
            ? `bottom/cover url(${props.backgroundImg})`
            : "#FFFFFF"};
  height: 100%;
  overflow: hidden;
  min-height: ${(props) => props.isWeb ? "670px" : "auto"};
`;

class Protocol extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.test()
    }

    test = async () => {
        const { ticket } = queryString.parse(window.location.search);
        const res = await Service.get(`/auc/tencnetprotocol/get-by-ticket?ticket=${ticket}`)

        if (res.scode === '200') {
            const { getPrmProtocol } = this.props
            getPrmProtocol(res.result)
        } else {
            return window.location.replace(`${window.location.protocol}//${window.location.host}/auc/sso-error`)
        }
    }

    render() {
        const {
            tenantError,
            onCloseMsgModal,
            prmProtocol,
            onAgree,
            onDisagree
        } = this.props;

        return (
            <Main>
                <MsgModal
                    show={
                        (!!tenantError)
                    }
                    msg={
                        tenantError
                    }
                    onClose={onCloseMsgModal}
                />
                <ProtocolModal
                    show={
                        (!!prmProtocol)
                    }
                    prmProtocol={prmProtocol}
                    disagree={onDisagree}
                    agree={() => {
                        // 小程序快速登录
                        if (prmProtocol?.source === 'channelwxmp') {
                            onAgree(prmProtocol)
                        } else {
                            window.location.assign(prmProtocol?.crmDomain + '/neologin/tenant/protocol/agree?ticket=' + prmProtocol?.ticket);
                        }
                    }}
                />
            </Main>
        )
    }
};

Protocol.propTypes = {
    tenantError: PropTypes.string,
    onCloseMsgModal: PropTypes.func.isRequired,
    getPrmProtocol: PropTypes.func,
    prmProtocol: PropTypes.shape({
        ticket: PropTypes.string,
        crmDomain: PropTypes.string,
        protocol: PropTypes.arrayOf(
            PropTypes.shape({
                protocolName: PropTypes.string,
                contentUri: PropTypes.string,
                refuseTxt: PropTypes.string,
                agreeTxt: PropTypes.string,
            })
        )
    }),
    onDisagree: PropTypes.func.isRequired,
    onAgree: PropTypes.func.isRequired
};

function mapStatesToProps(state) {
    return {
        tenantError: state.tenantError,
        prmProtocol: state.prmProtocol
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onCloseMsgModal() {
            dispatch(setTenantError(null));
        },
        getPrmProtocol(protocol) {
            dispatch(setPrmProtocol(protocol))
        },
        onDisagree(protocol) {
            dispatch(disagreePrmProtocol(protocol))
        },
        onAgree(protocol) {
            dispatch(agreePrmProtocol(protocol))
        }
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(Protocol);