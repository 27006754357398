/* eslint-disable */
export const loginStates = {
  // LOGINING: 'LOGINING',
  // SHOW_VERIFICATION: 'SHOW_VERIFICATION',
  SELECT_TENANT: "SELECT_TENANT",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESSFULLY: "RESET_PASSWORD_SUCCESSFULLY",
  AUTHENTICATION: "AUTHENTICATION",
  AUTHTOTP: 'AUTHTOTP',
  // LOGIN_SUCCESSFULLY: 'LOGIN_SUCCESSFULLY',
};

export const ResetPasswordType = {
  FIRST_LOGIN: "FIRST_LOGIN",
  RULES_CHANGED: "RULES_CHANGED",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
};

// 设置用户名
export const SET_USERNAME = "SET_USERNAME";
export function setUsername(username) {
  return {
    type: SET_USERNAME,
    payload: username,
  };
}
// 设置短信登录用户名
export const SET_SMSNAME = "SET_SMSNAME";
export function setSmsName(name) {
  return {
    type: SET_SMSNAME,
    payload: name,
  }
}

// 设置协议
export const PROTOCAL_CHANGED = "PROTOCAL_CHANGED";
export function protocalChanged(checkBoxRes) {
  return {
    type: PROTOCAL_CHANGED,
    payload: checkBoxRes,
  };
}



// 清空用户名
export const CLEAR_USERNAME = "CLEAR_USERNAME";
export function clearUserName() {
  return {
    type: CLEAR_USERNAME,
  };
}

// 设置密码
export const SET_PASSWORD = "SET_PASSWORD";
export function setPassword(password) {
  return {
    type: SET_PASSWORD,
    payload: password,
  };
}

// 设置新密码
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export function setNewPassword(newPassword) {
  return {
    type: SET_NEW_PASSWORD,
    payload: newPassword,
  };
}

// 设置再次确认的密码
export const SET_NEW_PASSWORD_AGAIN = "SET_NEW_PASSWORD_AGAIN";
export function setNewPasswordAgain(newPasswordAgain) {
  return {
    type: SET_NEW_PASSWORD_AGAIN,
    payload: newPasswordAgain,
  };
}

// 设置重置密码接口的错误
export const SET_NEW_PASSWORD_ERROR = "SET_NEW_PASSWORD_ERROR";
export function setNewPasswordError(newPasswordError) {
  return {
    type: SET_NEW_PASSWORD_ERROR,
    payload: newPasswordError,
  };
}

// 设置忘记密码中重置密码必须的secureCode参数
export const SET_SECURE_CODE = "SET_SECURE_CODE";
export function setSecureCode(newSecureCode) {
  return {
    type: SET_SECURE_CODE,
    payload: newSecureCode,
  };
}

// 设置忘记密码页面的短信验证码
export const SET_VERIFICATION_CODE = "SET_VERIFICATION_CODE";
export function setVerificationCode(newVerificationCode) {
  return {
    type: SET_VERIFICATION_CODE,
    payload: newVerificationCode,
  };
}
// 设置登录时短信验证码
export const SET_SMS_CODE = "SET_SMS_CODE";
export function setSmsCode(code) {
  return {
    type: SET_SMS_CODE,
    payload: code,
  };
}
// 设置是否显示收不到验证码的提示帮助
export const SET_SHOW_VERIFICATION_CODE_HELP_MESSAGE =
  "SET_SHOW_VERIFICATION_CODE_HELP_MESSAGE";
export function setShowVerificationCodeHelpMessage(flag) {
  return {
    type: SET_SHOW_VERIFICATION_CODE_HELP_MESSAGE,
    payload: flag,
  };
}

// 设置是否打开验证码提示的PopUp框
export const JUMP_TO_VERIFICATION_CODE_HELP_MESSAGE =
  "JUMP_TO_VERIFICATION_CODE_HELP_MESSAGE";
export function jumpToVerificationCodeHelpMessage(flag) {
  return {
    type: JUMP_TO_VERIFICATION_CODE_HELP_MESSAGE,
    payload: flag,
  };
}

// 设置获取短信验证码的加载状态
export const SET_IS_VERIFICATION_CODE_LOADING =
  "SET_IS_VERIFICATION_CODE_LOADING";
export function setIsVerificationCodeLoading(isLoading) {
  return {
    type: SET_IS_VERIFICATION_CODE_LOADING,
    payload: isLoading,
  };
}

// 设置忘记密码页面的请求错误
export const SET_FORGET_PASSWORD_ERROR = "SET_FORGET_PASSWORD_ERROR";
export function setForgetPasswordError(newForgetPasswordError) {
  return {
    type: SET_FORGET_PASSWORD_ERROR,
    payload: newForgetPasswordError,
  };
}

// 设置忘记密码页面的验证码接收账号
export const SET_FORGET_PASSWORD_RECEIVER = "SET_FORGET_PASSWORD_RECEIVER";
export function setForgetPasswordReceiver(forgetPasswordReceiver) {
  return {
    type: SET_FORGET_PASSWORD_RECEIVER,
    payload: forgetPasswordReceiver,
  };
}

// 设置忘记密码页面的验证码接收提示
export const SET_FORGET_PASSWORD_TIPS = "SET_FORGET_PASSWORD_TIPS";
export function setForgetPasswordTips(forgetPasswordTips) {
  return {
    type: SET_FORGET_PASSWORD_TIPS,
    payload: forgetPasswordTips,
  };
}

// 设置忘记密码页面的提示语的展示状态
export const SET_FORGET_PASSWORD_TIPS_SHOW = "SET_FORGET_PASSWORD_TIPS_SHOW";
export function setForgetPasswordShowTips(forgetPasswordShowTips) {
  return {
    type: SET_FORGET_PASSWORD_TIPS_SHOW,
    payload: forgetPasswordShowTips,
  };
}

// 设置是否是首次登录，需要修改密码
export const SET_RESET_PASSWORD_FLAG = "SET_RESET_PASSWORD_FLAG";
export function setResetPasswordFlag(flag) {
  return {
    type: SET_RESET_PASSWORD_FLAG,
    payload: flag,
  };
}

// 设置密码规则提示
export const SET_PASSWORD_RULES = "SET_PASSWORD_RULES";
export function setPasswordRules(passwordRules) {
  return {
    type: SET_PASSWORD_RULES,
    payload: passwordRules,
  };
}

// 设置获取密码规则时的错误
export const SET_PASSWORD_RULES_ERROR = "SET_PASSWORD_RULES_ERROR";
export function setPasswordRulesError(passwordRulesError) {
  return {
    type: SET_PASSWORD_RULES_ERROR,
    payload: passwordRulesError,
  };
}

export const SET_PASSWORD_TIMES = "SET_PASSWORD_TIMES";
export function setPasswordTimes(passwordTimes) {
  return {
    type: SET_PASSWORD_TIMES,
    payload: passwordTimes,
  }
}

// 设置passport信息
export const SET_PASSPORT = "SET_PASSPORT";
export function setPassport(passport) {
  return {
    type: SET_PASSPORT,
    payload: passport,
  };
}

// 设置passport报错信息
export const SET_PASSPORT_ERROR = "SET_PASSPORT_ERROR";
export function setPassportError(msg) {
  return {
    type: SET_PASSPORT_ERROR,
    payload: msg,
  };
}

// 设置选择租户的报错信息
export const SET_TENANT_ERROR = "SET_TENANT_ERROR";
export function setTenantError(msg) {
  return {
    type: SET_TENANT_ERROR,
    payload: msg,
  };
}

// 设置获取注册地址的报错信息
export const SET_REGISTER_ADDRESS_ERROR = "SET_REGISTER_ADDRESS_ERROR";
export function setRegisterAddressError(msg) {
  return {
    type: SET_REGISTER_ADDRESS_ERROR,
    payload: msg,
  };
}

// 设置SSO的报错信息
export const SET_SSO_ERROR = "SET_SSO_ERROR";
export function setSSOError(msg) {
  return {
    type: SET_SSO_ERROR,
    payload: msg,
  };
}

// 设置品牌登录的错误信息
export const SET_BRAND_ERROR = "SET_BRAND_ERROR";
export function setBrandError(msg) {
  return {
    type: SET_BRAND_ERROR,
    payload: msg,
  };
}

// 设置网络请求的错误信息
export const SET_NETWORK_ERROR = "SET_NETWORK_ERROR";
export function setNetworkError(msg) {
  return {
    type: SET_NETWORK_ERROR,
    payload: msg,
  };
}

// 设置致命错误的错误信息
export const SET_FATAL_ERROR = "SET_FATAL_ERROR";
export function setFatalError(msg) {
  return {
    type: SET_FATAL_ERROR,
    payload: msg,
  };
}

// 设置致命错误的错误信息
export const VERIFY_CODE_ERROR = "VERIFY_CODE_ERROR";
export function verifyCodeError(msg) {
  return {
    type: VERIFY_CODE_ERROR,
    payload: msg,
  };
}

// 处理致命错误
export const HANDLE_FATAL_ERROR = "HANDLE_FATAL_ERROR";
export function handleFatalError() {
  return {
    type: HANDLE_FATAL_ERROR,
  };
}

// 设置passportLoading状态
export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";
export function setLoginLoading(flag) {
  return {
    type: SET_LOGIN_LOADING,
    payload: flag,
  };
}

// 设置prm协议同意Loading状态
export const SET_AGREE_LOADING = "SET_AGREE_LOADING";
export function setAgreeLoading(flag) {
  return {
    type: SET_AGREE_LOADING,
    payload: flag,
  };
}

// 设置resetPasswordLoading状态
export const SET_RESET_PASSWORD_LOADING = "SET_RESET_PASSWORD_LOADING";
export function setResetPasswordLoading(flag) {
  return {
    type: SET_RESET_PASSWORD_LOADING,
    payload: flag,
  };
}

// 设置passportLoading状态
export const SET_FORGET_PASSWORD_LOADING = "SET_FORGET_PASSWORD_LOADING";
export function setForgetPasswordLoading(flag) {
  return {
    type: SET_FORGET_PASSWORD_LOADING,
    payload: flag,
  };
}

// 设置showAuthCode的值
export const SET_AUTH_CODE_INSTANCE = "SET_AUTH_CODE_INSTANCE";
export function setAuthCodeInstance(instance) {
  return {
    type: SET_AUTH_CODE_INSTANCE,
    payload: instance,
  };
}

// 设置NECaptchaValidate验证码信息
export const SET_NECAPTCHA_VALIDATE = "SET_NECAPTCHA_VALIDATE";
export function setNECaptchaValidate(code) {
  return {
    type: SET_NECAPTCHA_VALIDATE,
    payload: code,
  };
}

// 设置租户列表
export const CREATE_TENANT_LIST = "CREATE_TENANT_LIST";
export function createTenantList(tenantList) {
  return {
    type: CREATE_TENANT_LIST,
    payload: tenantList,
  };
}

// 一个小型路由，设置页面状态
export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export function setLoginState(loginState) {
  return {
    type: SET_LOGIN_STATE,
    payload: loginState,
  };
}

// 设置移动端特有的登录参数
export const SET_MOBILE_PARAMS = "SET_MOBILE_PARAMS";
export function setMobileParams(params) {
  return {
    type: SET_MOBILE_PARAMS,
    payload: params,
  };
}

// 设置页面是否初始化完成
export const SET_HAS_INITIALIZED = "SET_HAS_INITIALIZED";
export function setHasInitialized(flag) {
  return {
    type: SET_HAS_INITIALIZED,
    payload: flag,
  };
}

// 选择租户
export const SELECT_TENANT = "SELECT_TENANT";
export function selectTenant(tenantId) {
  return {
    type: SELECT_TENANT,
    payload: tenantId,
  };
}
// 企业微信认证选择租户 
export const SELECT_QYWX_LIST = "SELECT_QYWX_LIST";
export function seleQYWXList(id) {
  return {
    type: SELECT_QYWX_LIST,
    payload: id,
  }
}
// 清空所有租户的选中状态
export const CLEAR_SELECTED_TENANT = "CLEAR_SELECTED_TENANT";
export function clearSelectedTenant() {
  return {
    type: CLEAR_SELECTED_TENANT,
  };
}

// 设置是否开始初始化
export const START_INIT = "START_INIT";
export function startInit() {
  return {
    type: START_INIT,
  };
}

// 设置登录页的scope范围：all、crm、prm
export const SET_SCOPE = "SET_SCOPE";
export function setScope(scope) {
  return {
    type: SET_SCOPE,
    payload: scope,
  };
}

// 设置登录页的login_type范围：all、crm、prm
export const SET_LOGIN_TYPE = "SET_LOGIN_TYPE";
export function setLoginType(loginType) {
  return {
    type: SET_LOGIN_TYPE,
    payload: loginType,
  };
}

// 设置三级域名
export const SET_SUB_DOMAIN = "SET_SUB_DOMAIN";
export function setSubDomain(subDomain) {
  return {
    type: SET_SUB_DOMAIN,
    payload: subDomain,
  };
}

// 设置注册地址
export const SET_REGISTER_ADDRESS = "SET_REGISTER_ADDRESS";
export function setRegisterAddress(address) {
  return {
    type: SET_REGISTER_ADDRESS,
    payload: address,
  };
}

// 设置SSO相关信息
export const SET_SSO_INFO = "SET_SSO_INFO";
export function setSSOInfo(SSOInfo) {
  return {
    type: SET_SSO_INFO,
    payload: SSOInfo,
  };
}

// 设置品牌相关信息
export const SET_BRAND_INFO = "SET_BRAND_INFO";
export function setBrandInfo(brandInfo) {
  return {
    type: SET_BRAND_INFO,
    payload: brandInfo,
  };
}

// 点击登录按钮
export const SUBMIT_LOGIN = "SUBMIT_LOGIN";
export function submitLogin() {
  return {
    type: SUBMIT_LOGIN,
  };
}

// 点击选择租户
export const SUBMIT_TENANT = "SUBMIT_TENANT";
export function submitTenant(id) {
  return {
    type: SUBMIT_TENANT,
    payload: id,
  };
}
//企业微信选择租户ID
export const SET_ID = "SET_ID";
export function changeData(id) {
  return {
    type: SET_ID,
    payload: id,
  };
}

//企微选择租户列表
export const SET_DATA_LIST = "SET_DATA_LIST";
export function qywxlist(result) {
  return {
    type: SET_DATA_LIST,
    payload: result
  }
}
// 选择租户后进行跳转
export const FETCH_TENANT = "FETCH_TENANT";
export function fetchTenant(id, code, type, totpSec) {
  return {
    type: FETCH_TENANT,
    payload: id,
    code,
    mfa_type: type,
    totp_sec: totpSec
  };
}

// 点击重置密码
export const SUBMIT_RESET_PASSWORD = "SUBMIT_RESET_PASSWORD";
export function submitResetPassword() {
  return {
    type: SUBMIT_RESET_PASSWORD,
  };
}

// 请求租户信息
export const FETCH_PASSPORT = "FETCH_PASSPORT";
export function fetchPassport() {
  return {
    type: FETCH_PASSPORT,
  };
}

// 企微租户信息请求
export const FEATCH_QYWX_PASSPORT = "FEATCH_QYWX_PASSPORT";
export function featchQYWXpassport() {
  return {
    type: FEATCH_QYWX_PASSPORT,
  }
}
// 请求忘记密码页面的短信验证码接口
export const FETCH_VERIFICATION_CODE = "FETCH_VERIFICATION_CODE";
export function fetchVerificationCode() {
  return {
    type: FETCH_VERIFICATION_CODE,
  };
}
// 请求获取短信验证吗接口
export const FEATCH_MSG_VERIFY = "FEATCH_MSG_VERIFY";
export function fetchMsgCode() {
  return {
    type: FEATCH_MSG_VERIFY,
  };
}

// 点击验证身份按钮
export const SUBMIT_VERIFICATION = "SUBMIT_VERIFICATION";
export function submitVerification() {
  return {
    type: SUBMIT_VERIFICATION,
  };
}
// 短信验证登录 
export const MSG_SEND = 'MSG_SEND';
export function submitMssage(result) {
  console.log(result, "短信验证码登录0000")
  return {
    type: MSG_SEND,
    payload: result  //选择租户数组
  }
}
// 跳转登录页
export const JUMP_TO_LOGIN = "JUMP_TO_LOGIN";
export function jumpToLogin() {
  return {
    type: JUMP_TO_LOGIN,
  };
}

// 跳转到选择租户页面
export const JUMP_TO_SELECT_TENANT = "JUMP_TO_SELECT_TENANT";
export function jumpToSelectTenant() {
  return {
    type: JUMP_TO_SELECT_TENANT,
  };
}

// 跳转重置密码页面
export const JUMP_TO_RESET_PASSWORD = "JUMP_TO_RESET_PASSWORD";
export function jumpToResetPassword({ resetPasswordFlag }) {
  return {
    type: JUMP_TO_RESET_PASSWORD,
    payload: { resetPasswordFlag },
  };
}

// 跳转忘记密码
export const JUMP_TO_FORGET_PASSWORD = "JUMP_TO_FORGET_PASSWORD";
export function jumpToForgetPassword() {
  return {
    type: JUMP_TO_FORGET_PASSWORD,
  };
}

// 跳转免费注册
export const JUMP_TO_REGISTER = "JUMP_TO_REGISTER";
export function jumpToRegister() {
  return {
    type: JUMP_TO_REGISTER,
  };
}

// 跳转隐私条款
export const JUMP_TO_PRIVACY_POLICY = "JUMP_TO_PRIVACY_POLICY";
export function jumpToPrivacyPolicy() {
  return {
    type: JUMP_TO_PRIVACY_POLICY,
  };
}

//设置全局错误提示
export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";
export function setGlobalError(msg) {
  return {
    type: SET_GLOBAL_ERROR,
    payload: msg,
  };
}

//获取全局加密公钥
export const SET_GLOBAL_PASSWORD_KEY = "SET_GLOBAL_PASSWORD_KEY";
export function setGlobalPasswordKey(msg) {
  return {
    type: SET_GLOBAL_PASSWORD_KEY,
    payload: msg,
  };
}

//跳转TOTP认证页面
export const JUMP_TO_TOTP = "JUMP_TO_TOTP";
export function jumpToTOTP(msg) {
  return {
    type: JUMP_TO_TOTP,
    payload: msg,
  };
}

// 保存双认证类型列表信息
export const AUTH_TYPE_INFO = "AUTH_TYPE_INFO";
export function setAuthTypeInfo(data) {
  return {
    type: AUTH_TYPE_INFO,
    payload: data,
  };
}

// prm签署协议
export const PRM_PROTOCOL = "PRM_PROTOCOL";
export function setPrmProtocol(data) {
  return {
    type: PRM_PROTOCOL,
    payload: data
  };
}

// 拒绝prm协议
export const PRM_PROTOCOL_DISAGREE = "PRM_PROTOCOL_DISAGREE";
export function disagreePrmProtocol(data) {
  return {
    type: PRM_PROTOCOL_DISAGREE,
    payload: data
  };
}

// 同意prm协议
export const PRM_PROTOCOL_AGREE = "PRM_PROTOCOL_AGREE";
export function agreePrmProtocol(data) {
  return {
    type: PRM_PROTOCOL_AGREE,
    payload: data
  };
}