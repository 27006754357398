/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { defineMessages } from '#utils/i18n/intl';
import AvatarMonitor from '#utils/sentry/AvatarMonitor';

const messages = defineMessages({
  agreementReject: {
    id: "prm.login.agreement.reject",
    defaultMessage: "拒绝协议将退出当前登录，确定拒绝吗？"
  },
  cancelButton: {
    id: 'neo.prm.login.agreement.cancel',
    defaultMessage: '取消',
  },
  confirmButton: {
    id: 'neo.prm.login.agreement.confirm',
    defaultMessage: '确定',
  },
});

const Wrapper = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 16px;

  .message {
    margin-top: 16px;
    padding: 24px;
    color: #454545;
    text-align: center;
  }

  .modal_foot {
    display: flex;
    height: 70px;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

  .modal_refuse {
      height: 20px;
      min-width: 110px;
      max-width: 150px;
      margin-right: 4px;
      padding: 5px 3px;
      border: 1px solid #AAA;
      border-radius: 100px;
      text-align: center;
      font-size: 13px;
      color: #454545;
      &:hover {
        cursor: pointer;
      }
    }

  .modal_agree {
    height: 20px;
    min-width: 110px;
    max-width: 150px;
    margin-left: 4px;
    padding: 5px 3px;
    background-color: #0564F5;
    border-radius: 100px;
    text-align: center;
    font-size: 13px;
    color: #FFF;
    &:hover {
      cursor: pointer;
    }
  }
}
`;

ReactModal.setAppElement('#modal-slot');

class ConfirmModal extends React.Component {

  componentDidUpdate() {
    const { show } = this.props;
    if (show) {
      AvatarMonitor.getInstance().report();
    }
  }

  render() {
    const { show, onCancel, onConfirm } = this.props;

    return (
      <ReactModal
        isOpen={show}
        style={{
          overlay: {
            zIndex: 102,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            padding: 0,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <Wrapper>
          <span className='message'>
            {intl.formatMessage(messages.agreementReject)}
          </span>
          <div className='modal_foot'>
            <span className='modal_refuse' onClick={onCancel}>
              {intl.formatMessage(messages.cancelButton)}
            </span>
            <span className='modal_agree' onClick={onConfirm}>
              {intl.formatMessage(messages.confirmButton)}
            </span>
          </div>
        </Wrapper>
      </ReactModal>
    );
  }
}

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default connect()(ConfirmModal);