/* eslint-disable */
import Button from '#components/Button';
import Input from '#components/Input';
import MsgModal from '#components/MsgModal';
import TOTPShowHelpMsg from '#components/TOTPShowHelpMsg';
import Center from '#components/layout/Center';
import { defineMessages } from '#utils/i18n/intl';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Header from './components/Header';
import ProtocolModal from '#components/ProtocolModal';

import {
  jumpToVerificationCodeHelpMessage,
  setSSOError,
  setBrandError,
  setNetworkError,
  handleFatalError,
  fetchTenant,
  setLoginLoading,
  verifyCodeError as setVerifyCodeError,
  disagreePrmProtocol,
  agreePrmProtocol
} from '#models/login/action';

const messages = defineMessages({
  helpMsgTitle_1: {
    id: 'TOTPBind.helpMsgTitle_1',
    defaultMessage: '如何获取身份认证器？',
  },
  helpMsgTitle_2: {
    id: 'TOTPBind.helpMsgTitle_2',
    defaultMessage: '如果身份认证器不小心卸载或者手机更换/丢失，如何进行登录？',
  },
  helpMsgLine_1: {
    id: 'TOTPBind.helpMsgLine_1',
    defaultMessage: '如您企业内部没有推荐使用的身份认证器，可以通过手机官方应用市场搜索“Authenticator”，选择可靠的身份认证器进行使用，推荐使用“MIcrosoft Authenticator”或者“腾讯身份验证器”；或者在微信中搜索“腾讯身份验证器”小程序进行使用。',
  },
  helpMsgLine_2: {
    id: 'TOTPBind.helpMsgLine_2',
    defaultMessage: '绝大部分身份认证器应用都支持云端备份，建议开启备份，避免因应用卸载或者设备丢失后，无法获取TOTP令牌。',
  },
  helpMsgLine_3: {
    id: 'TOTPBind.helpMsgLine_3',
    defaultMessage: '如果您之前使用的身份认证器启用了云端备份，应用重新安装并同步后，即可正常获取TOTP令牌。',
  },
  helpMsgLine_4: {
    id: 'TOTPBind.helpMsgLine_4',
    defaultMessage: '如果您之前未启用云端备份，请联系您的租户管理员帮您解绑之前的身份认证器绑定信息，解绑后再次登录可重新进行绑定。',
  },
  totpSettingTitle: {
    id: 'TOTPBind.totpSettingTitle',
    defaultMessage: '设置您的身份认证器',
  },
  totpSettingQrSecondTitle: {
    id: 'TOTPBind.totpSettingQrSecondTitle',
    defaultMessage: '请使用身份认证器扫描以下二维码，完成扫码后请选择下一步',
  },
  totpSettingSecSecondTitle: {
    id: 'TOTPBind.totpSettingSecSecondTitle',
    defaultMessage: '请在身份认证器中输入以下密钥，完成后请选择下一步',
  },
  unableToScan: {
    id: 'TOTPBind.unableToScan',
    defaultMessage: '无法扫码？',
  },
  nextStep: {
    id: 'TOTPBind.nextStep',
    defaultMessage: '下一步',
  },
  unableToLogin: {
    id: 'TOTPBind.unableToLogin',
    defaultMessage: '无法登录？',
  },
  askForHelp: {
    id: 'TOTPBind.askForHelp',
    defaultMessage: '查看帮助',
  },
  totpAuthenticationTitle: {
    id: 'TOTPBind.totpAuthenticationTitle',
    defaultMessage: '验证您的身份',
  },
  totpAuthenticationSecondTitle: {
    id: 'TOTPBind.totpAuthenticationSecondTitle',
    defaultMessage: '请输入身份证器上生成的验证码',
  },
  totpHasAuthenticationSecondTitle: {
    id: 'TOTPBind.totpHasAuthenticationSecondTitle',
    defaultMessage: '您正在尝试通过【{encodeUsername}】登录【{tenantName}】，为确保您的账户安全，请输入身份认证器上生成的验证码',
  },
  authenticationPlaceholder: {
    id: 'TOTPBind.authenticationPlaceholder',
    defaultMessage: '请输入您的验证码',
  },
  verify: {
    id: 'TOTPBind.verify',
    defaultMessage: '验证',
  },

});

const SignInTipWrapper = styled.div`
  margin: 0 0 8px;
`;

const TOTPTitle = styled.div`
  color: #333;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 16px;
`;

const TOTPSecondTitle = styled.div`
  color: #787878;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px;
`;

const TOTPPicArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TOTPPic = styled.div`
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-image: ${props => props.imgSrc};
  margin-bottom: 8px;
`;

const TOTPScanChage = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  color: #2065CF;
  margin-bottom: 8px;
`

const TOTPCodeArea = styled.div`
  border-radius: 4px;
  color: background: var(--text-secondary, #787878);
  border: 1px solid #D6D6D6;
  background: #F6F6F6;
  display: flex;
  padding: 16px 40px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;

  color: #787878;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

class TOTPBind extends React.Component {
  constructor(props) {
    super(props);
    const { username, passport } = props;
    this.selectedTenantDetail = {
      username,
      passportId: passport,
      tenantDetail: this.getTenantDetail(),
    };
    this.state = {
      currentStep: 'toBind',
      canScanTwoDimensionalCode: true,
      qrcode: '', // 二维码图片base64码 
      totpSec: undefined, // 密钥base64码 
      verificationCode: '', // 验证码
      selectList: [], // 选择的租户信息
      currentUser: '', // 租户的
      currentType: '3', // 3是固定使用TOTP方式
      showHelpMsg: false
    };
  }

  getTenantDetail = () => {
    const { tenantList } = this.props;
    const { byId = [] } = tenantList;
    const selectedTenant = Object.values(byId).filter(item => item.selected);
    return selectedTenant.length > 0 ? {
      tenantName: selectedTenant[0].company,
      tenantId: selectedTenant[0].id,
      encryptionKey: selectedTenant[0].encryptionKey,
      user_type: selectedTenant[0].user_type,
    } : {
      tenantName: '',
      tenantId: '',
      encryptionKey: '',
      user_type: '0',
    };
  }

  goToNextStep = (currentStep) => {
    switch (currentStep) {
      case 'toBind':
        this.setState({ currentStep: 'toAuthentication' })
        break;
      case 'toAuthentication':
        this.verifyClickHandle()
        break;
      default:
        break;
    }
  }

  onVerificationCodeInput = (e) => {
    this.setState({
      verificationCode: e.target.value
    })
  }

  verifyClickHandle = () => {
    const { tenantDetail } = this.selectedTenantDetail;
    const { verificationCode, currentType, totpSec } = this.state;
    const { fetchTenantHandle } = this.props;
    fetchTenantHandle(tenantDetail.tenantId, verificationCode, currentType, totpSec);
  }

  getDesc = () => {
    const { tenantDetail: { tenantName } } = this.selectedTenantDetail;
    const username = localStorage.getItem("lastLoginUserId") || "";
    let encodeUsername = '';
    if (username.indexOf('@') >= 0) {
      encodeUsername = username.replace(/(.{2}).*(@).*(.{2}\.com)/, '$1******$2*$3');
    } else {
      encodeUsername = username.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    }
    return intl.formatMessage(messages.totpHasAuthenticationSecondTitle, { encodeUsername, tenantName });
  }

  onOpenHelpModal = () => {
    this.setState({
      showHelpMsg: true
    })
  }

  onCloseHelpModal = () => {
    this.setState({
      showHelpMsg: false
    })
  }




  componentDidMount() {
    const { authTypeInfo } = this.props;
    if (authTypeInfo) {
      this.setState({
        currentStep: authTypeInfo.totp.bind == 0 ? 'toBind' : 'toAuthentication',
        totpSec: authTypeInfo.totp.sec,
        qrcode: `url("${authTypeInfo.totp.qrcode}")`
      });
    }
  };

  render() {
    const {
      verifyCodeError,
      buttonLoading,
      authTypeInfo,
      registerAddressError,
      SSOError,
      brandError,
      networkError,
      fatalError,
      onCloseMsgModal,
      prmProtocol,
      onAgree,
      onDisagree } = this.props;
    const { currentStep, canScanTwoDimensionalCode, totpSec, verificationCode } = this.state;
    return (
      <Center
        showHeader
        renderHeader={() => (<Header />)}
      >
        {
          currentStep === 'toBind' ?
            <>
              <SignInTipWrapper>
                <TOTPTitle>
                  {intl.formatMessage(messages.totpSettingTitle)}
                </TOTPTitle>
                <TOTPSecondTitle>
                  {
                    canScanTwoDimensionalCode ?
                      intl.formatMessage(messages.totpSettingQrSecondTitle) :
                      intl.formatMessage(messages.totpSettingSecSecondTitle)
                  }
                </TOTPSecondTitle>
              </SignInTipWrapper>
              <TOTPPicArea>
                {
                  canScanTwoDimensionalCode ?
                    <>
                      <TOTPPic imgSrc={this.state.qrcode} />
                      <TOTPScanChage onClick={() => this.setState({
                        canScanTwoDimensionalCode: false
                      })}>
                        {intl.formatMessage(messages.unableToScan)}
                      </TOTPScanChage>
                    </> :
                    <TOTPCodeArea>
                      {atob(totpSec)}
                    </TOTPCodeArea>
                }
              </TOTPPicArea>
              <Button
                loading={false}
                active={true}
                onClick={() => this.goToNextStep(currentStep)}
                data-ta-key="next_btn"
              >
                {intl.formatMessage(messages.nextStep)}
              </Button>
            </> : null
        }
        {
          currentStep === 'toAuthentication' ?
            <>
              <SignInTipWrapper>
                <TOTPTitle>
                  {intl.formatMessage(messages.totpAuthenticationTitle)}
                </TOTPTitle>
                <TOTPSecondTitle>
                  {
                    authTypeInfo.totp.bind == 0
                      ? intl.formatMessage(messages.totpAuthenticationSecondTitle)
                      : this.getDesc()
                  }
                </TOTPSecondTitle>
              </SignInTipWrapper>
              <Input
                value={verificationCode}
                placeholder={intl.formatMessage(messages.authenticationPlaceholder)}
                onChange={this.onVerificationCodeInput}
                // onKeyUp={this.onPressEnter}
                type="text"
                name="verificationCode"
                errorMsg={verifyCodeError}
                data-ta-key="verificationCode_input"
              />
              <Button
                loading={buttonLoading}
                active={verificationCode}
                onClick={this.verifyClickHandle}
                data-ta-key="verify_btn"
              >
                {intl.formatMessage(messages.verify)}
              </Button>
            </> : null
        }
        <TOTPShowHelpMsg
          onOpenHelpModal={this.onOpenHelpModal}
          onCloseHelpModal={this.onCloseHelpModal}
          showHelpMsg={this.state.showHelpMsg}
        />
        <MsgModal
          show={
            (!!SSOError)
            || (!!brandError)
            || (!!registerAddressError)
            || (!!networkError)
            || (!!fatalError)
          }
          msg={
            SSOError
            || brandError
            || registerAddressError
            || networkError
            || fatalError
          }
          onClose={onCloseMsgModal}
        />
        <ProtocolModal
          show={
            (!!prmProtocol)
          }
          prmProtocol={prmProtocol}
          disagree={onDisagree}
          agree={onAgree}
        />
      </Center>
    )
  }
}

TOTPBind.propTypes = {
  onOpenHelpModal: PropTypes.func.isRequired,
  onCloseHelpModal: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  onCloseMsgModal: PropTypes.func.isRequired,
  registerAddressError: PropTypes.string,
  SSOError: PropTypes.string,
  brandError: PropTypes.string,
  networkError: PropTypes.string,
  fatalError: PropTypes.string,
  fetchTenantHandle: PropTypes.func.isRequired,
  authTypeInfo: PropTypes.object.isRequired,
  prmProtocol: PropTypes.shape({
      ticket: PropTypes.string,
      crmDomain: PropTypes.string,
      protocol: PropTypes.arrayOf(
        PropTypes.shape({
          protocolName: PropTypes.string,
          contentUri: PropTypes.string,
          refuseTxt: PropTypes.string,
          agreeTxt: PropTypes.string,
        })
      )
    }),
    onDisagree: PropTypes.func.isRequired,
    onAgree: PropTypes.func.isRequired
};

TOTPBind.defaultProps = {
  registerAddressError: null,
  SSOError: null,
  brandError: null,
  networkError: null,
  fatalError: null,
  prmProtocol: null
};

// connect组件
function mapStateToProps(state) {
  return {
    username: state.username,
    passport: state.passport,
    tenantList: state.tenantList,
    buttonLoading: state.loginLoading,
    verifyCodeError: state.verifyCodeError,
    registerAddressError: state.registerAddressError,
    SSOError: state.SSOError,
    brandError: state.brandError,
    networkError: state.networkError,
    fatalError: state.fatalError,
    authTypeInfo: state.authTypeInfo,
    prmProtocol: state.prmProtocol
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCloseMsgModal() {
      dispatch(setSSOError(null));
      dispatch(setBrandError(null));
      dispatch(setNetworkError(null));
      dispatch(handleFatalError());
    },
    fetchTenantHandle(id, code, type, totpSec) {
      dispatch(setLoginLoading(true));
      dispatch(fetchTenant(id, code, type, totpSec));
    },
    setVerifyCodeErrorHandle(value) {
      dispatch(setVerifyCodeError(value));
    },
    onDisagree(protocol) {
      dispatch(disagreePrmProtocol(protocol))
    },
    onAgree(protocol) {
      dispatch(agreePrmProtocol(protocol))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TOTPBind);
