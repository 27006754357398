/* eslint-disable */
import { call, put } from "redux-saga/effects";
import { setLoginLoading, setAgreeLoading, setFatalError } from "../action";
import {
  getBFFService,
  getTenantSuccess,
  status,
  wxLogin
} from "../LoginService";
import intl from "react-intl-universal";
import getScodeMessages from "#utils/i18n/getScodeMessages";

export default function* prmProtocolAgree(action) {
  const { ticket, crmDomain, source } = action.payload
  // 小程序快速登录
  if (source === 'channelwxmp') {
    yield call(wxLogin, ticket)
  } else {
    try {
      const bffRes = yield call(getBFFService, crmDomain + '/neologin/tenant/protocol/agree?ticket=' + ticket);
      if (!bffRes) {
        yield put(setLoginLoading(false));
        yield put(setAgreeLoading(false));
        return;
      }
      if (bffRes.scode === status.OK) {
        yield call(getTenantSuccess, bffRes.result);
      } else {
        // 登录请求失败
        // 1、设置请求错误信息
        yield put(
          setFatalError(
            intl.formatMessage(getScodeMessages(bffRes.scode), {
              errorMsg: bffRes.error_msg
            })
          )
        );
        yield put(setLoginLoading(false));
        yield put(setAgreeLoading(false));
      }
    } catch (error) {
      yield put(setLoginLoading(false));
      yield put(setAgreeLoading(false));
    }
  }
}