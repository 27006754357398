import SubmitInput from '#components/ SubmitInput';
import Button from '#components/Button';
import MsgModal from '#components/MsgModal';
import Select from '#components/Select';
import ShowHelpMsg from '#components/ShowHelpMsg';
import Center from '#components/layout/Center';
import { Service } from '#models/Service';
import {
  fetchTenant,
  handleFatalError,
  jumpToVerificationCodeHelpMessage,
  setBrandError,
  setLoginLoading,
  setNetworkError,
  setSSOError,
  verifyCodeError as setVerifyCodeError,
  disagreePrmProtocol,
  agreePrmProtocol
} from '#models/login/action';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import { defineMessages } from '#utils/i18n/intl';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Header from './Header';
import ProtocolModal from '#components/ProtocolModal';

const AuthTitleWrap = styled.div`
  font-size: 24px;
  color: #444D5C;
  text-align: center;
  line-height: 32px;
  font-weight: 600;
`;

const AuthDescWrap = styled.div`
  font-size: 13px;
  color: #aaaaaa;
  text-align: center;
  line-height: 16px;
  margin-top: 12px;
`;

const messages = defineMessages({
  authTitle: {
    id: 'Authentication.authTitle',
    defaultMessage: '验证您的身份',
  },
  authDesc: {
    id: 'Authentication.authDesc',
    defaultMessage: '您正在尝试登录{tenantName}，为确保您的帐户安全，请输入我们发送到{phone}的验证码。',
  },
  codePlaceholder: {
    id: 'Authentication.codePlaceholder',
    defaultMessage: '请输入您的验证码',
  },
  verifyCode: {
    id: 'Authentication.verifyCode',
    defaultMessage: '验证',
  },
  againText: {
    id: 'Send.send',
    defaultMessage: '发送',
  },
});

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    const { username, passport } = props;
    this.selectedTenantDetail = {
      username,
      passportId: passport,
      tenantDetail: this.getTenantDetail(),
    };
    this.timer = null;
  }

  state = {
    codeValue: '',
    selectList: [],
    currentUser: '',
    currentType: '',
  }

  componentDidMount() {
    const { closeHelpMsg, authTypeInfo } = this.props;
    closeHelpMsg();
    if (authTypeInfo) {
      // 结构调整后的
      this.setState({ selectList: authTypeInfo.account, currentUser: authTypeInfo.account[0].value, currentType: authTypeInfo.account[0].type });
    }
    // this.setFormatList();
  }
  getSelectData = async () => {
    const { username, passportId, tenantDetail } = this.selectedTenantDetail;
    const { setVerifyCodeErrorHandle } = this.props;
    try {
      const res = await Service.post('/auc/mfa/list', {
        loginName: username,
        passportId,
        tenantId: tenantDetail.tenantId,
      });
      if (res.scode !== '200') {
        const errorMsg = intl.formatMessage(getScodeMessages(res.scode || 1000000));
        if (errorMsg) {
          setVerifyCodeErrorHandle(errorMsg);
        }
        return 'error';
      }
      return res;
    } catch (err) {
      console.log(err);
      return 'error';
    }
  }

  getTenantDetail = () => {
    const { tenantList } = this.props;
    const { byId = [] } = tenantList;
    const selectedTenant = Object.values(byId).filter(item => item.selected);
    return selectedTenant.length > 0 ? {
      tenantName: selectedTenant[0].company,
      tenantId: selectedTenant[0].id,
      encryptionKey: selectedTenant[0].encryptionKey,
      user_type: selectedTenant[0].user_type,
    } : {
      tenantName: '',
      tenantId: '',
      encryptionKey: '',
      user_type: '0',
    };
  }

  changeCodeValue = (e) => {
    this.setState({
      codeValue: e.target.value,
    });
  }

  verifyClickHandle = () => {
    const { tenantDetail } = this.selectedTenantDetail;
    const { codeValue, currentType } = this.state;
    const { fetchTenantHandle } = this.props;
    fetchTenantHandle(tenantDetail.tenantId, codeValue, currentType);
  }


  setFormatList = async () => {
    const res = await this.getSelectData();
    let selectList = res.result;
    if (res === 'error') {
      return;
    }
    this.setState({ selectList, currentUser: selectList[0].value, currentType: selectList[0].type });
  }

  getDesc = () => {
    const { tenantDetail: { tenantName } } = this.selectedTenantDetail;
    const { currentUser } = this.state;
    let encodeUsername = '';
    if (currentUser.indexOf('@') >= 0) {
      encodeUsername = currentUser.replace(/(.{2}).*(@).*(.{2}\.com)/, '$1******$2*$3');
    } else {
      encodeUsername = currentUser.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    }
    return intl.formatMessage(messages.authDesc, { phone: encodeUsername, tenantName });
  }

  changeUser = (element) => {
    element
      && this.setState({
        currentUser: element.value, currentType: element.type, codeValue: '',
      });
  }

  getSubmitInput = () => {
    const {
      codeValue,
      selectList,
      currentUser,
    } = this.state;
    const {
      verifyCodeError,
      setVerifyCodeErrorHandle,
    } = this.props;
    let userInfo = this.selectedTenantDetail;
    let requestData = {
      currentUser, setVerifyCodeErrorHandle, userInfo,
    };
    return selectList.map((element, index) => {
      let className = index == 0 ? 'firstChild' : 'secondChild';
      return (
        <SubmitInput
          codeValue={codeValue}
          verifyCodeError={verifyCodeError}
          codePlaceholder={intl.formatMessage(messages.codePlaceholder)}
          onChange={this.changeCodeValue}
          onFocus={() => { setVerifyCodeErrorHandle(null); }}
          message={intl.formatMessage(messages.againText)}
          request={requestData}
          type={className}
        />
      );
    });
  }

  render() {
    const {
      codeValue,
      selectList,
    } = this.state;
    const {
      buttonLoading,
      verifyCodeError,
      registerAddressError,
      SSOError,
      brandError,
      networkError,
      fatalError,
      onCloseMsgModal,
      prmProtocol,
      onAgree,
      onDisagree
    } = this.props;
    return (
      <Center
        showHeader
        renderHeader={() => (<Header />)}
      >
        <AuthTitleWrap>{intl.formatMessage(messages.authTitle)}</AuthTitleWrap>
        <AuthDescWrap>{this.getDesc()}</AuthDescWrap>
        {this.getSubmitInput()}
        {selectList.length > 1 && (
          <Select
            onChange={this.changeUser}
            data={selectList}
            errorMsg={verifyCodeError}
          />
        )}
        <Button
          loading={buttonLoading}
          active={codeValue}
          onClick={buttonLoading ? null : this.verifyClickHandle}
          data-ta-key="verify_btn"
        >
          {intl.formatMessage(messages.verifyCode)}
        </Button>
        <ShowHelpMsg />
        <MsgModal
          show={
            (!!SSOError)
            || (!!brandError)
            || (!!registerAddressError)
            || (!!networkError)
            || (!!fatalError)
          }
          msg={
            SSOError
            || brandError
            || registerAddressError
            || networkError
            || fatalError
          }
          onClose={onCloseMsgModal}
        />
        <ProtocolModal
          show={
            (!!prmProtocol)
          }
          prmProtocol={prmProtocol}
          disagree={onDisagree}
          agree={onAgree}
        />
      </Center>
    );
  }
}

Authentication.propTypes = {
  username: PropTypes.string.isRequired,
  passport: PropTypes.number.isRequired,
  tenantList: PropTypes.any.isRequired, // eslint-disable-line
  buttonLoading: PropTypes.bool.isRequired,
  verifyCodeError: PropTypes.string,
  fetchTenantHandle: PropTypes.func.isRequired,
  closeHelpMsg: PropTypes.func.isRequired,
  onCloseMsgModal: PropTypes.func.isRequired,
  setVerifyCodeErrorHandle: PropTypes.func.isRequired,
  registerAddressError: PropTypes.string,
  SSOError: PropTypes.string,
  brandError: PropTypes.string,
  networkError: PropTypes.string,
  fatalError: PropTypes.string,
  authTypeInfo: PropTypes.object.isRequired,
  prmProtocol: PropTypes.shape({
    ticket: PropTypes.string,
    crmDomain: PropTypes.string,
    protocol: PropTypes.arrayOf(
      PropTypes.shape({
        protocolName: PropTypes.string,
        contentUri: PropTypes.string,
        refuseTxt: PropTypes.string,
        agreeTxt: PropTypes.string,
      })
    )
  }),
  onDisagree: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired
};

Authentication.defaultProps = {
  verifyCodeError: null,
  registerAddressError: null,
  SSOError: null,
  brandError: null,
  networkError: null,
  fatalError: null,
  prmProtocol: null
};

function mapStateToProps(state) {
  return {
    username: state.username,
    passport: state.passport,
    tenantList: state.tenantList,
    buttonLoading: state.loginLoading,
    verifyCodeError: state.verifyCodeError,
    registerAddressError: state.registerAddressError,
    SSOError: state.SSOError,
    brandError: state.brandError,
    networkError: state.networkError,
    fatalError: state.fatalError,
    authTypeInfo: state.authTypeInfo,
    prmProtocol: state.prmProtocol
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTenantHandle(id, code, type) {
      dispatch(setLoginLoading(true));
      dispatch(fetchTenant(id, code, type));
    },
    closeHelpMsg() {
      dispatch(jumpToVerificationCodeHelpMessage(false));
    },
    onCloseMsgModal() {
      dispatch(setSSOError(null));
      dispatch(setBrandError(null));
      dispatch(setNetworkError(null));
      dispatch(handleFatalError());
    },
    setVerifyCodeErrorHandle(value) {
      dispatch(setVerifyCodeError(value));
    },
    onDisagree(protocol) {
      dispatch(disagreePrmProtocol(protocol))
    },
    onAgree(protocol) {
      dispatch(agreePrmProtocol(protocol))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
